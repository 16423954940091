* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  background: #000 url('img/bg.jpg') no-repeat center center/cover;
  height: 100vh;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1100px;
  margin: auto;
  padding: 0 20px;
}

.btn {
  display: inline-block;
  color: #fff;
  background-color: #3fb573;
  font-size: 1em;
  text-align: center;
  padding: 10px 15px;
  border: 0;
  margin: 10px 0;
}

header {
  height: 200px;
}

header img {
  width: 200px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  height: 100px;
}

input[type='text'] {
  display: block;
  padding: 10px;
  font-size: 20px;
  border: 0;
  border-radius: 5px;
  width: 60%;
  margin: auto;
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.card {
  cursor: pointer;
  background-color: transparent;
  height: 300px;
  perspective: 1000px;
}

.card h1 {
  font-size: 25px;
  border-bottom: 1px #fff solid;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-back {
  background-color: #333;
  color: white;
  padding: 20px;
  transform: rotateY(180deg);
}

.card li {
  list-style: none;
  padding-bottom: 10px;
}

@media (max-width: 800px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .cards {
    grid-template-columns: 1fr;
  }
}

.single-page {
  display: flex;
}
.single-page .left, .single-page .right {
  flex: 1;
}
.single-page .left {
  margin-right: 2rem;
}
.single-page p {
  font-size: 2rem;
  margin-bottom: 1rem;
  display: flex;
}
.single-page span.soft {
  color: #137b3b;
  font-size: 1.8rem;
  flex: 1 1;
}
.single-page span.white {
  display: inline-block;
  color: white;
  flex: 1;
}
.single-page img {
  width: 100%;
  display: block;
}
.single-page .quote {
  margin-top: 8rem;
  display: block;
  text-align: center;
  font-size: 1.6rem;
  font-style: italic;
}
@media (max-width: 800px) {
  .single-page {
    flex-direction: column;
  }
  .single-page .quote {
    margin: 4rem 0;
  }
  .single-page .left {
    margin-right: 0;
  }
}